import React, { useState, useEffect, useRef } from 'react';
import QuillEditor from '../QuillEditor';
import styled from 'styled-components';
import { projectSlice } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';

const JobDescriptionFormLarge = () => {
  const [isTextAreaFocused, setTextAreaFocus] = useState(false);
  const [isTextAreaEmpty, setTextAreaEmpty] = useState(true);
  const [displayInstructions, setDisplayInstructions] =
    useState(true);

  const dispatch = useDispatch();
  const { job } = useSelector((state) => state.project);

  const quillRef = useRef();

  // setTextAreaEmpty to false if jobDescription is not empty
  useEffect(() => {
    if (job) {
      if (job.length > 0) {
        setTextAreaEmpty(false);
      } else {
        setTextAreaEmpty(true);
      }
    } else if (job === '' || job === null) {
      setTextAreaEmpty(true);
    }
  }, [job]);

  useEffect(() => {
    const quill = quillRef?.current?.editor;
    if (quill) {
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });
    }
  }, []);

  // remove instructions if user is typing
  useEffect(() => {
    if (isTextAreaEmpty === false && displayInstructions === true) {
      setDisplayInstructions(false);
    } else if (isTextAreaEmpty === true) {
      setDisplayInstructions(true);
    }
  }, [isTextAreaEmpty, isTextAreaFocused]);

  const handleEditorChange = (content, delta, source, editor) => {
    const plainText = editor.getText(); // Get plain text content
    setJobDescription(plainText); // Store the plain text content
  };

  const setJobDescription = (jobDescription) => {
    dispatch(projectSlice.actions.setJob(jobDescription));
  };

  return (
    <div className="flex flex-col justify-center items-start space-y-2 w-full">
   
   <div className="flex flex-row items-center text-[18px] md:text-[18px] text-[#325b90] joker2">
  <span className="w-8 h-8 flex items-center justify-center bg-blue-400 text-white rounded-full mr-2">b</span>
  <span>Paste the Job Description here...</span>
</div>

      <JobDescriptionFormLargeWrapper
        className={
          'w-full relative flex flex-col justify-between items-center px-2 border-[#9494E5] border-[0.5px] md:border rounded-[4px] md:rounded-[16px] '
        }
        id="myEditorLarge"
      >
        <QuillEditor
          quillRef={quillRef}
          setJobDescription={setJobDescription}
          jobDescription={job}
          onChange={handleEditorChange}
          setTextAreaFocus={setTextAreaFocus}
        />

        {isTextAreaEmpty ? (
          <span className="border-l-[1px] self-start border-brand-purple-light/50 text-[#9494E5] pl-2 mt-4 opacity-70 mx-7">
            Copy + Paste the job description here
          </span>
        ) : (
          ''
        )}

        {isTextAreaEmpty && (
          <div className="mt-[-36px]">
            <img src="/images/icons/cv.png" alt="upload" />
          </div>
        )}
        <div className="" />
      </JobDescriptionFormLargeWrapper>
    </div>
  );
};

export default JobDescriptionFormLarge;

const JobDescriptionFormLargeWrapper = styled.div`
  height: 320px;
  @media (min-width: 768px) {
    height: 540px;
  }

  .ql-editor::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }

  .ql-editor {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .ql-container,
  .ql-toolbar,
  .ql-editor {
    border: none !important;
    overflow: hidden !important;
  }

  .ql-editor {
    max-height: 534px;
    overflow-y: scroll !important;
    scrollbar-width: none; /* Firefox 64+ */
  }

  .ql-editor {
    color: #4f4f4f;
    font-size: 16px;
  }

  .ql-snow .ql-stroke {
    stroke: rgba(78, 26, 144, 0.6);
  }
  .ql-snow .ql-stroke-miter {
    stroke: rgba(78, 26, 144, 0.6);
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: rgba(78, 26, 144, 0.6);
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button,
  .ql-snow.ql-toolbar .ql-picker-label,
  .ql-snow .ql-toolbar .ql-picker-label {
    color: rgba(78, 26, 144, 0.6);
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: rgba(78, 26, 144, 1);
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow
    .ql-toolbar
    .ql-picker-item.ql-selected
    .ql-stroke.ql-fill {
    fill: rgba(78, 26, 144, 1);
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: rgba(78, 26, 144, 1);
  }
`;
