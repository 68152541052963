import React, { useState } from 'react';
import { ResumeLayout } from '../Layouts';
import {
  HorizontalResumeTab,
  VerticalResumeTab,
} from '../common/Tabs';
import ResumeForm from '../common/Forms/ResumeForm';
import Insights from '../common/Insights';
import { useSelector } from 'react-redux';
import resumeTabs from '../../constants/resumeTabs';

const CompareResume = ({ resume }) => {
  const { resumeTitle } = useSelector((state) => state.project);

  const [data, setData] = useState({
    ...resume.content,
    title: resume?.title,
  });

  const [selectedTab, setSelectedTab] = React.useState('');

  const handleTabClick = (ref) => {
    setSelectedTab(ref);
  };

  return (
    <div className="w-full max-h-screen lg:overflow-y-auto hide-scrollbar">
      <ResumeLayout
        VerticalResumeTab={
          <VerticalResumeTab
            tabs={resumeTabs}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
          />
        }
        HorizontalResumeTab={
          <HorizontalResumeTab
            tabs={resumeTabs}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
          />
        }
        ResumeForm={
          <ResumeForm
            selectedTab={selectedTab}
            data={data}
            setData={setData}
          />
        }
        NextStep={<Insights data={data} />}
      />
    </div>
  );
};

export default CompareResume;
