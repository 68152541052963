import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { checkmark } from '../../../assets/lotties';
import { RiTwitterXFill } from 'react-icons/ri';
import { MdOutlineContentCopy } from 'react-icons/md';
import { FaLinkedin } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import ReactGA from 'react-ga4';

function FeedbackForm({ closeModal }) {
  const [formState, setFormState] = useState({
    rating: '',
    painPoint: '',
    feedback: '',
  });

  const [feedbackIsSubmitted, setFeedbackIsSubmitted] =
    useState(false);

  const feedbackOptions = {
    "FEEDBACK_NO_ISSUES": "I have no issues",
    "FEEDBACK_DISLIKES_TEMPLATE_DESIGNS": "I don’t like the resume templates designs",
    "FEEDBACK_WRONG_CV_DETAILS_EXTRACTED": "My resume details were not correctly extracted",
    "FEEDBACK_PROCESS_TOOK_TOO_LONG": "The process took too long to complete",
    "FEEDBACK_CV_NOT_UPDATED_AFTER_FIXES": "My resume wasn't properly updated after fixing it",
    "FEEDBACK_OTHER": "Others..."
  };

  const handleRatingChange = (e) => {
    e.preventDefault();
    setFormState({ ...formState, rating: e.target.value });
  };

  const handlePainPointChange = (e) => {
    setFormState({ ...formState, painPoint: e.target.value });
  };

  const handleFeedbackChange = (e) => {
    setFormState({ ...formState, feedback: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFeedbackIsSubmitted(true);
    ReactGA.event({
      category: 'FEEDBACK',
      action: formState.painPoint,
      label: formState.feedback,
    });
    console.log(formState);
  };

  const image = feedbackIsSubmitted ? (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        animationData: checkmark,
      }}
      height={200}
      width={200}
    />
  ) : (
    <img src="/images/icons/feedback-icon.png" alt="Feedback icon" />
  );

  const title = feedbackIsSubmitted ? (
    <span className="text-[#2D9CDB] text-[24px] md:text-[24px] text-center mb-7 w-full flex justify-center">
      {' '}
      Thank you for your feedback!
    </span>
  ) : (
    <span className="text-black text-[24px] md:text-[24px] text-center mb-7 w-full flex justify-center">
      We value your feedback 🙏
    </span>
  );

  const content = feedbackIsSubmitted ? (
    <ThanksForFeedbackCard />
  ) : (
    <FeedbackFormContent
      formState={formState}
      handleRatingChange={handleRatingChange}
      handlePainPointChange={handlePainPointChange}
      handleFeedbackChange={handleFeedbackChange}
      handleSubmit={handleSubmit}
      feedbackOptions={feedbackOptions}
    />
  );

  return (
    <Container className="w-full lg:w-[660px] lg:h-[682px] p-20 bg-white m-6 lg:m-0">
      <div className="w-full flex justify-center mb-4">{image}</div>
      {title}
      {content}
      {closeModal && (
        <button
          onClick={() => closeModal()}
          className="lg:flex absolute top-0 right-0 text-black opacity-40 text-[32px] mt-6 mr-6"
        >
          <img src="/images/icons/x.png" alt="close icon" />
        </button>
      )}
    </Container>
  );
}

function FeedbackFormContent({
  formState,
  handleRatingChange,
  handlePainPointChange,
  handleFeedbackChange,
  handleSubmit,
  feedbackOptions,
}) {
  const isSubmitDisabled = !formState.painPoint;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
      {/* <div className="flex flex-col items-start space-y-2">
        <span className="text-[#5F6368] text-[14px] leading-6">
          Rate your experience so far
        </span>
        <div className="flex justify-start items-center space-x-12">
          {['😡', '😞', '😐', '😊', '😍'].map((emoji) => (
            <button
              key={emoji}
              onClick={handleRatingChange}
              className={`m-0 text-[32px] text-center ${formState.rating === emoji ? 'bg-brand-blue' : ''} h-[42px] w-[42px] flex justify-center items-center rounded-full`}
              value={emoji}
            >
              {emoji}
            </button>
          ))}
        </div>
      </div> */}

      <div>
        <label
          htmlFor="painPoint"
          className="block  leading-6 text-[#5F6368] text-[16px]"
        >
          What did you struggle with while using revats
        </label>
        <select
          id="painPoint"
          name="painPoint"
          className="mt-2 block w-full rounded-md border-0 py-2 lg:py-4 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={formState.painPoint}
          onChange={handlePainPointChange}
        >
          <option value="" disabled>
            --Select--
          </option>
          {Object.entries(feedbackOptions).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label
          htmlFor="feedback"
          className="block  leading-6 text-[#5F6368] text-[16px]"
        >
          Any other ideas to help you enjoy Revats better?
        </label>
        <textarea
          onChange={handleFeedbackChange}
          id="feedback"
          name="feedback"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          rows="4"
          value={formState.feedback}
        ></textarea>
      </div>
      <button
        type="submit"
        className={`py-4 rounded-md w-fit px-8 flex self-end ${
          isSubmitDisabled
            ? 'bg-[#B2DFFC] text-gray-500'
            : 'bg-[#2D9CDB] text-white'
        }`}
        disabled={isSubmitDisabled}
      >
        Submit Feedback
      </button>
    </form>
  );
}

function ThanksForFeedbackCard() {
  const handleShare = (platform) => {
    const url = 'https://revats.ai';
    const text = 'Improve your resume with revats.ai. Get an ATS score and helpful tips to enhance your CV.';

    let shareUrl = '';

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`;
        break;
      case 'copy':
        navigator.clipboard
          .writeText(url)
          .then(() => {
            alert('Link copied to clipboard');
          })
          .catch((err) => {
            alert('Failed to copy the link');
          });
        return; // Exit the function since we don't need to open a new window
      default:
        return null;
    }

    window.open(shareUrl, '_blank');
  };

  return (
    <div className="flex flex-col space-y-4">
      <span className="text-[#383838] text-[14px] font-medium leading-6 text-center">
        We're glad you've found value in
        <span className="text-brand-blue"> Revats!</span> <br /> Do
        you know friends who are also in search of their dream jobs?{' '}
        <br /> Help them out by sharing Revats with them.
      </span>
      <div className="flex flex-col justify-center items-center space-y-3">
        <button
          onClick={() => handleShare('linkedin')}
          className="text-[14px] flex justify-center items-center space-x-2 text-[#383838] py-[10px] px-6 rounded-3xl bg-[#E8E8E8] cursor"
        >
          <div className="text-[#0967C2]">
            <FaLinkedin />
          </div>
          <span className="">Share on Linkedin</span>
        </button>
        <button
          onClick={() => handleShare('facebook')}
          className="text-[14px] flex justify-center items-center space-x-2 text-[#383838] py-[10px] px-6 rounded-3xl bg-[#E8E8E8] cursor"
        >
          <div className="text-[#0866FF]">
            <FaFacebook />
          </div>
          <span className="">Share on Facebook</span>
        </button>
        <button
          onClick={() => handleShare('twitter')}
          className="text-[14px] flex justify-center items-center space-x-2 text-[#383838] py-[10px] px-6 rounded-3xl bg-[#E8E8E8] cursor"
        >
          <div className="">
            <RiTwitterXFill />
          </div>
          <span className="">Share on Twitter</span>
        </button>
        <button
          onClick={() => handleShare('copy')}
          className="text-[14px] flex justify-center items-center space-x-2 text-[#383838] py-[10px] px-6 rounded-3xl bg-[#EEF9FF] cursor"
        >
          <div className="text-brand-blue">
            <MdOutlineContentCopy />
          </div>
          <span className="">Copy Link</span>
        </button>
      </div>
    </div>
  );
}

export default FeedbackForm;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 13px 0px rgba(88, 113, 132, 0.1);
`;
