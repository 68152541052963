import React from 'react';
import ResumeCardSmall from './Cards/ResumeCardSmall';
import UploadNewResumeBtn from './Buttons/UploadNewResumeBtn';
import { useSelector, useDispatch } from 'react-redux';
import formatDate from '../../utils/formatDate';

function FeaturedResumes() {
  const { activeResume, resumes } = useSelector(
    (state) => state.project
  );
  const dispatch = useDispatch();

  // Separate the active resume and other resumes
  const activeResumeObj = resumes.find(
    (resume) => resume.id === activeResume?.id
  );
  const otherResumes = resumes.filter(
    (resume) => resume.id !== activeResume?.id
  );

  // Combine active resume at the top with other resumes
  const sortedResumes = activeResumeObj
    ? [activeResumeObj, ...otherResumes]
    : otherResumes;

  return (
    <div className="flex flex-col justify-center items-start space-y-2 w-full">
<div className="flex flex-row items-center text-[18px] md:text-[18px] text-[#325b90] joker2">
  <span className="w-8 h-8 flex items-center justify-center bg-blue-400 text-white rounded-full mr-2">a</span>
  <span>Select a CV or Upload a new one</span>
</div>
      <div
        className={`mt-9 flex md:flex-col justify-start space-x-3 md:space-x-0 items-start md:space-y-6 md:items-center w-full ${
          activeResume &&
          'md:max-h-[540px] overflow-x-auto md:overflow-x-hidden md:overflow-y-auto pb-3 md:pb-0 md:pr-3'
        }`}
      >
        {sortedResumes?.map((resume) => {
          const { content, last_updated, id, title } = resume;
          const lastUpdated = formatDate(last_updated);
          return (
            <ResumeCardSmall
              key={id}
              title={title}
              lastUpdated={lastUpdated}
              content={content}
              id={id}
            />
          );
        })}
        <UploadNewResumeBtn />
      </div>
    </div>
  );
}

export default FeaturedResumes;
