import splitDateRange from "../splitDateRange";
import { v4 as uuidv4 } from "uuid";

const filterEducation = (education) => {
  if (!education || education.length === 0 || education === null) return [];
  return education?.map((e) => {
    const date = splitDateRange(e.date);
    if (date) {
      return {
        ...e,
        id: e.id || uuidv4(),
        date,
      };
    } else {
      return { ...e, id: e.id || uuidv4() };
    }
  });
};

export default filterEducation;
