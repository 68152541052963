import React, { useEffect, useState } from "react";
import ApplicationCard from "../common/ApplicationCard";
import { CreateNewApplication } from "../common/Buttons";
import NoProjectCreated from "../common/NoProjectCreated";
import FadeIn from "react-fade-in/lib/FadeIn";
import formatDate from "../../utils/formatDate";
import { useSelector } from "react-redux";

const Applications = () => {
  const { resumes: applications, defaultResume } = useSelector(
    (state) => state.project
  );

  const [lastApplication, setLastApplication] = useState(null);
  const [applicationCount, setApplicationCount] = useState(0); // count of active applications

  useEffect(() => {
    setApplicationCount(applications?.length || 0);
  }, [applications]);

  // get the index of the last application
  useEffect(() => {
    if (!applications) return;
    const lastApplicationIndex = applicationCount - 1;
    setLastApplication(lastApplicationIndex);
  }, [applications, applicationCount]);

  return (
    <FadeIn className="px-3 md:px-4 xl:px-[300px] flex flex-col py-8 ">
      <Title applicationsLength={applicationCount} />

      {applicationCount > 0 ? (
        <div className="space-y-6 md:space-y-0">
          {applications?.map((application, i) => {
            const { content, last_updated, id, title } = application;
            const lastUpdated = formatDate(last_updated);
            return (
              <div className="md:py-4 " key={i}>
                <ApplicationCard
                  applicationThemeColor={content.themeColor || "#000"}
                  title={title}
                  lastUpdated={lastUpdated}
                  content={content}
                  id={id}
                  defaultResume={id === defaultResume?.id}
                />
                {i < lastApplication && (
                  <hr className="hidden md:block mt-8 ml-10 mr-[250px] border-[#C0E2FB]" />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-[60px]">
          <NoProjectCreated />
        </div>
      )}
    </FadeIn>
  );
};

const Title = ({ applicationsLength }) => {
  const { premiumUser } = useSelector((state) => state.auth);

  return (
    <div className="flex justify-between items-center md:block mb-4">
      <div className="flex flex-col text-[14px] md:text-[18px] md:flex-row md:items-center md:justify-start md:space-x-1 text-black dark:text-white">
        <span>Your Active Projects </span>
        {!premiumUser && (
          <span className=" text-[10px] text-[#9A9A9A] md:text-black md:dark:text-white md:text-[18px]">
            ({2 - applicationsLength} more Projects to go in your free trial)
          </span>
        )}
      </div>
      <div className="md:hidden">
        <CreateNewApplication />
      </div>
    </div>
  );
};

export default Applications;
