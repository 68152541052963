import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import { authApi, useGetUserDetailsMutation } from './apis/authApi';
import projectSlice from './slices/projectSlice';
import { projectApi } from './apis/projectApi';
import modalSlice from './slices/modalSlice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    project: projectSlice.reducer,
    modal: modalSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(projectApi.middleware),
});

export {
  store,
  authSlice,
  projectSlice,
  modalSlice,
  useGetUserDetailsMutation,
};

export {
  useResumesMutation,
  useUploadPdfMutation,
  useCreateResumeMutation,
  useGetScoreMutation,
  useEnhanceResumeMutation,
  useUpdateResumeMutation,
  useExportResumeMutation,
  useDeleteResumeMutation,
  useSetDefaultResumeMutation,
} from './apis/projectApi';
