import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { modalSlice, useExportResumeMutation } from '../../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { SpinnerLoader } from '../Loader';
import reactiveResumeModel from '../../../data/reactiveResumeModel';
import {
  formatEducation,
  formatCertifications,
  formatWorkExperience,
  formatSkills,
} from '../../../utils/formatResumeData';

const ResumeTemplates = ({ closeModal, modalIsOpen }) => {
  const templates = [
    { img: '/images/resumeTemplates/kakuna.png', name: 'kakuna' },
    { img: '/images/resumeTemplates/pikachu.png', name: 'pikachu' },
    { img: '/images/resumeTemplates/glalie.png', name: 'glalie' },
    { img: '/images/resumeTemplates/castform.png', name: 'castform' },
    { img: '/images/resumeTemplates/onyx.png', name: 'onyx' },
    { img: '/images/resumeTemplates/gengar.png', name: 'gengar' },
  ];

  const [selectedTemplate, selectTemplate] = useState(templates[0]);
  const { enhancedResume, resumeId } = useSelector(
    (state) => state.project
  );

  const [formattedData, setFormattedData] = useState({});

  const [exportResume, { isLoading, error, data }] =
    useExportResumeMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  if (!enhancedResume) {
    navigate('/user-profile');
  }

  // Format the resume data
  useEffect(() => {
    const formattedEducation = formatEducation(
      enhancedResume?.education
    );
    const formattedWork = formatWorkExperience(
      enhancedResume?.experiences
    );
    const formattedCertifications = formatCertifications(
      enhancedResume?.certificates
    );
    const formattedSkills = formatSkills(enhancedResume?.skills);

    const formattedData = {
      name: enhancedResume?.name,
      email: enhancedResume?.email,
      phone: enhancedResume?.phone,
      summary: enhancedResume?.summary,
      education: formattedEducation,
      work: formattedWork,
      certifications: formattedCertifications,
      skills: formattedSkills,
      location: {
        city: '',
        region: '',
        address: '',
        country: '',
        postalCode: '',
      },
      'first-name': '',
      'last-name': '',
    };
    setFormattedData(formattedData);
  }, [enhancedResume]);

  // Download the resume
  useEffect(() => {
    if (data) {
      const url = data.url;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `resume.pdf`);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);

      if (pathname !== '/export-resume') {
        closeModal();
      }

      dispatch(modalSlice.actions.setDownloadSuccessful(true));

      navigate('/project-view');
    }
  }, [data]);

  const handleExportResume = () => {
    const resume = {
      ...reactiveResumeModel,
      basics: {
        ...reactiveResumeModel.basics,
        name: formattedData.name,
        email: formattedData.email,
        phone: formattedData.phone,
        summary: formattedData.summary,
        location: formattedData.location,
      },
      sections: {
        ...reactiveResumeModel.sections,
        work: {
          ...reactiveResumeModel.sections.work,
          items: formattedData.work,
        },
        education: {
          ...reactiveResumeModel.sections.education,
          items: formattedData.education,
        },
        skills: {
          ...reactiveResumeModel.sections.skills,
          items: formattedData.skills,
        },
        certifications: {
          ...reactiveResumeModel.sections.certifications,
          items: formattedData.certifications,
        },
      },
      metadata: {
        ...reactiveResumeModel.metadata,
        template: selectedTemplate.name,
      },
    };

    exportResume({ resume_id: resumeId, content: resume });
  };

  const handleReturnToEditing = () => {
    pathname !== '/export-resume' ? closeModal() : navigate(-1);
  };

  if (error) {
    console.log(error);
  }

  return (
    <Container
      pathname={pathname}
      className="xl:relative w-full max-w-[1161px] xl:max-h-[804px] flex flex-col items-center justify-center xl:p-8 bg-white  xl:pt-16"
    >
      {error && (
        <span className="text-red-500 text-xl ">
          {' '}
          An error occured, please try again
        </span>
      )}
      <span className="text-[24px] md:text-[24px] text-black text-center mb-1 px-4 joker2 ">
        Export your CV using any of our beautiful templates!
      </span>
      <div className="layout xl:space-x-6 w-full h-full">
        <div className="flex xl:flex-col justify-start items-center space-x-9 xl:space-x-0 xl:space-y-3 overflow-auto xl:h-[800px] max-w-full mb-12 h-full max-h-full">
          {templates.map((template, i) => (
            <button
              key={i}
              onClick={() => selectTemplate(template)}
              className={`min-w-[142px] min-h-[172px] w-[142px] h-[172px] p-4 rounded-[8px] flex justify-center items-center ${
                selectedTemplate.name === template.name
                  ? 'bg-[#C8DAF3]'
                  : ''
              }`}
            >
              <img
                src={template.img}
                alt="resume template"
                className="w-full h-full object-contain"
              />
            </button>
          ))}
        </div>

        <div className="xl:h-[560px] xl:w-[608px] hidden xl:flex justify-center items-start lg:mt-10 ">
          <img
            src={selectedTemplate.img}
            className="w-full h-full object-contain"
            alt="chosen resume template"
          />
        </div>

        <div className="flex flex-col justify-center h-full items-center w-full space-y-4 xl:w-[224px] px-4">
          <button
            onClick={handleExportResume}
            className="flex justify-center items-center space-x-2 w-full py-3 px-5 rounded bg-brand-blue"
          >
            <div className="w-[32px] h-[22px] ">
              <img src="/images/icons/pdf-white.svg" alt="pdf" />
            </div>
            <span className="font-medium text-[14px] text-white">
              Export as PDF
            </span>
          </button>

          <button
            onClick={handleReturnToEditing}
            className="flex justify-center items-center w-full py-3 px-5  border-[#BDBDBD]"
          >
            <span className="font-medium text-[14px] text-[#828282]">
              Cancel{' '}
            </span>
          </button>
        </div>
      </div>
      {modalIsOpen && (
        <button
          onClick={closeModal}
          className="hidden xl:block text-black opacity-40 absolute top-0 right-0 mt-9 mr-11 text-[32px]"
        >
          X
        </button>
      )}
      {isLoading && <SpinnerLoader />}
    </Container>
  );
};

export default ResumeTemplates;

const Container = styled.div`
  @media (max-width: 1280px) {
    padding: ${({ pathname }) =>
      pathname !== '/export-resume' && '20px 10px'};
  }
  .layout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-height: 100%;
    overflow: hidden;

    @media (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
      justify-content: center;
      align-items: start;
    }
  }
`;
