import React, { useEffect, useRef } from 'react';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import styled from 'styled-components';

export default function ToastEditor({
  initialValue = '',
  height = '500px',
  initialEditType = 'wysiwyg',
  previewStyle = 'tab',
  onChange,
  hideModeSwitch = true, // This will hide the mode switch button
  toolbarItems,
  usageStatistics = false,
  onEditorInstance,
}) {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);

  useEffect(() => {
    // Initialize the editor once on mount
    editorInstanceRef.current = new Editor({
      el: editorRef.current,
      initialValue, // Set initial value only once
      height,
      initialEditType,
      previewStyle,
      hideModeSwitch, // Pass the hideModeSwitch prop to hide the Markdown tab
      usageStatistics,
      toolbarItems: toolbarItems || [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task'],
        ['indent', 'outdent'],
        ['table', 'link'],
      ],
    });

    if (onChange) {
      editorInstanceRef.current.on('change', () => {
        const markdownContent =
          editorInstanceRef.current.getMarkdown();
        onChange(markdownContent);
      });
    }

    if (onEditorInstance) {
      onEditorInstance(editorInstanceRef.current);
    }

    // Cleanup the editor instance on component unmount
    return () => {
      editorInstanceRef.current.destroy();
    };
  }, []);

  return <div className="border rounded-xl w-full" ref={editorRef} />;
}
