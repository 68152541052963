import { v4 as uuidv4 } from "uuid";

function formatCertifications(certificates) {
  if (!certificates) return [];
  return certificates.map((certificate) => ({
    id: certificate.id || uuidv4(),
    name: certificate.name || "",
    issuer: certificate.issuer || "",
    summary: certificate.summary || "",
    date: certificate.date || "",
    url: certificate.url || "",
  }));
}

export default formatCertifications;
