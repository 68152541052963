import React, { useState } from "react";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useScreenWidth } from "../../../hooks";
import { useSelector } from "react-redux";
import Portal from "../../common/Portal";
import { ModalWrapperStyle } from "../../common/StyledComponents";
import { TrailEndedCard } from "../../common/Cards";
import { UploadResumeCard } from "../../common/Cards";
import ModalWrapper from "../ModalWrapper";

const CreateNewApplication = ({
  text = "New Project",
  textOnMobile = false,
}) => {
  const screenWidth = useScreenWidth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { createResumePermission } = useSelector((state) => state.auth);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = React.useState(false);

  // close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const navigate = useNavigate();
  return (
    <>
      <button
        onClick={() => {
          if (!createResumePermission) {
            setUpgradeModalIsOpen(true);
            return;
          }
          if (screenWidth > 1280) {
            setModalIsOpen(true);
          } else {
            navigate("/upload-resume");
          }
        }}
        className={`${
          textOnMobile ? "w-[186px] h-[48px] " : "w-[48px] h-[38px]"
        } md:w-[186px] md:h-[48px] flex justify-center items-center bg-brand-blue text-white rounded-[4px] space-x-3 text-[14px] `}
      >
        <span className="w-4 h-4 flex items-center justify-center">
          <Plus />
        </span>
        <span className={`${textOnMobile ? "block" : "hidden md:block"}`}>
          {text}
        </span>
      </button>
      {upgradeModalIsOpen && (
        <Portal>
          <ModalWrapperStyle className="w-full h-full">
            <TrailEndedCard setUpgradeModalIsOpen={setUpgradeModalIsOpen} />
          </ModalWrapperStyle>
        </Portal>
      )}

      {modalIsOpen && screenWidth > 1280 && (
        <Portal className="w-full h-full">
          <ModalWrapper setShowMenu={setModalIsOpen} showMenu={modalIsOpen}>
            <UploadResumeCard closeModal={closeModal} />
          </ModalWrapper>
        </Portal>
      )}
    </>
  );
};

export default CreateNewApplication;
