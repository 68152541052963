import React, { useRef, useState, useEffect } from 'react';
import { Circle, Plus } from 'react-feather';
import { CurrentStepCard, ResumeMatchingScoreCard } from '../Cards';
import { useSelector } from 'react-redux';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { v4 as uuidv4 } from 'uuid';
import { numToRomanNumeralsPair } from '../../../constants';
import { ExportCvBtn, UndoChangesBtn } from '../Buttons';
import ToastEditor from './ToastEditor';

const ResumeForm = ({ selectedTab, data, setData }) => {
  const refs = {
    section1: useRef(),
    section2: useRef(),
    section3: useRef(),
    section4: useRef(),
    section5: useRef(),
  };

  const { page } = useSelector((state) => state.project);

  // Scroll to selected tab
  useEffect(() => {
    if (refs[selectedTab]) {
      refs[selectedTab].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedTab]);

  // Split full name into first and last name
  useEffect(() => {
    if (!data?.name) return;
    function splitFullName(fullName) {
      const names = fullName.trim().split(/\s+/);
      const lastName = names.length > 1 ? names.pop() : '';
      const firstName = names.join(' ');
      return { firstName, lastName };
    }

    const { firstName, lastName } = splitFullName(data.name);

    setData((prevData) => ({
      ...prevData,
      'first-name': firstName,
      'last-name': lastName,
    }));
  }, [data?.name]);

  const handleDateRangeChange = (e, i, section) => {
    const { name, value } = e.target;
    const list = [...data[section]];
    list[i] = { ...list[i], [name]: value };
    setData((prevData) => ({ ...prevData, [section]: list }));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleAddLink = (e, newLink) => {
    e.preventDefault();
    if (!data.links) {
      setData((prevData) => ({
        ...prevData,
        links: [newLink],
      }));
      return;
    } else {
      setData((prevData) => ({
        ...prevData,
        links: [...prevData.links, newLink],
      }));
    }
  };

  const Congratulations = (
    <div className="flex flex-col justify-center items-center w-full ">
      <div className="mb-2 flex justify-center">
        <img
          src="/images/gifs/success.gif"
          alt="success"
          className="w-[100%] h-[100%]"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center text-black font-normals mb-10">
        <span className="text-brand-green text-xl lg:text-3xl mb-2 joker2">
          Awesome!
        </span>
        <span className="mb-2">
          Your CV has been updated to match the job description!
        </span>
        <span>
          Now, you can export your CV using any of our beautiful
          templates. <br />
          We are rooting for you!{' '}
          <span className="font-bold">Good luck!</span>
        </span>
      </div>
      <div className="flex justify-center space-x-6 w-fit ">
        <div>
          <ExportCvBtn data={data} />
        </div>
        <div>
          <UndoChangesBtn />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-full">
      <div className="mb-8 mx-3 mt-4 lg:mt-0 md:mx-0">
        {page === 2 && (
          <div className="flex items-center space-x-3">
            {' '}
            <ResumeMatchingScoreCard />
            {/* <CurrentStepCard /> */}
          </div>
        )}
        {page === 3 && Congratulations}
      </div>
      <form className="bg-white lg:bg-[#D9D9D9] lg:bg-opacity-10 px-3 md:px-10 py-6 flex flex-col w-full h-full space-y-[58px] ">
        <div ref={refs.section1}>
          <PersonalInformation
            handleChange={handleChange}
            data={data}
            setData={setData}
          />
        </div>
        <div ref={refs.section2}>
          <Skills setData={setData} data={data} />
        </div>
        <div ref={refs.section3} className="w-full">
          <Education
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
            data={data}
          />
        </div>
        <div ref={refs.section4} className="w-full">
          <WorkExperience
            data={data}
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
        <div className="w-full">
          <Certificates data={data} setData={setData} />
        </div>
        <div ref={refs.section5} />
        {data.links ? (
          <Links
            handleChange={handleChange}
            links={data.links}
            handleAddLink={handleAddLink}
          />
        ) : (
          <NewLink handleAddLink={handleAddLink} />
        )}
      </form>
    </div>
  );
};
export default ResumeForm;

const Links = ({ handleChange, links, handleAddLink }) => {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-[24px] md:text-[28px]">Links </span>
      <div className="flex flex-wrap space-x-2">
        {links.map((link, i) => (
          <div className="w-full md:max-w-[48%] " key={i}>
            <div className="flex justify-start flex-col items-center space-x-2 space-y-5 w-full mb-5">
              <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                <TextInput
                  label={link.title}
                  type="text"
                  name="link"
                  value={link.value}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <NewLink handleAddLink={handleAddLink} />
    </div>
  );
};

const NewLink = ({ handleAddLink }) => {
  const [newLinkTitle, setNewLinkTitle] = useState('');
  const [newLinkValue, setNewLinkValue] = useState('');

  const clearFields = () => {
    setNewLinkTitle('');
    setNewLinkValue('');
  };
  return (
    <div className="flex flex-col space-y-2">
      <TextInput
        label="Link Title"
        type="text"
        value={newLinkTitle}
        handleChange={(e) => setNewLinkTitle(e.target.value)}
      />
      <TextInput
        label="Link Value"
        type="text"
        value={newLinkValue}
        handleChange={(e) => setNewLinkValue(e.target.value)}
      />
      <button
        onClick={(e) => {
          handleAddLink(e, {
            title: newLinkTitle,
            value: newLinkValue,
          });
          clearFields();
        }}
        className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
      >
        <Plus className="mr-1 h-[12px] w-[12px] mb-10" />
        Add New Link
      </button>
    </div>
  );
};

const Certificates = ({ data, setData }) => {
  const certifications = data.certifications;

  const handleCertificateChange = (e, i) => {
    const { name, value } = e.target;
    const list = [...data.certifications];
    list[i][name] = value;
    setData({ ...data, certifications: list });
  };

  const handleAddCertificate = (e) => {
    e.preventDefault();
    if (!data.certifications) {
      setData((prevData) => ({
        ...prevData,
        certifications: [
          {
            id: uuidv4(),
            name: '',
            date: '',
            issuer: '',
            summary: '',
            url: '',
          },
        ],
      }));
      return;
    } else {
      setData((prevData) => ({
        ...prevData,
        certifications: [
          ...prevData.certifications,
          {
            id: uuidv4(),
            name: '',
            date: '',
            issuer: '',
            summary: '',
            url: '',
          },
        ],
      }));
    }
  };
  return (
    <div className="flex flex-col space-y-2">
      {certifications ? (
        <>
          <span className="text-[24px] md:text-[28px]">
            Certifications and Awards{' '}
          </span>

          {certifications.map((certificate, i) => (
            <div className="" key={certificate.id}>
              <div className="flex justify-start flex-col items-center space-x-2 space-y-5 w-full mb-5">
                <div className="w-full flex flex-col space-x-2 justify-center items-center">
                  <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                    <TextInput
                      label="Certificate Name"
                      type="text"
                      name="name"
                      value={certificate.name}
                      handleChange={(e) => {
                        handleCertificateChange(e, i);
                      }}
                    />
                    <TextInput
                      label="Issuer"
                      type="text"
                      name="issuer"
                      value={certificate.issuer}
                      handleChange={(e) => {
                        handleCertificateChange(e, i);
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                    <TextInput
                      label="URL"
                      type="text"
                      name="url"
                      value={certificate.url}
                      handleChange={(e) => {
                        handleCertificateChange(e, i);
                      }}
                    />

                    <DateInput
                      label="Date Achieved"
                      type="text"
                      name="date"
                      value={certificate.date}
                      handleChange={(e) => {
                        handleCertificateChange(e, i);
                      }}
                    />
                  </div>
                  <TextInput
                    label="Summary"
                    type="textArea"
                    name="summary"
                    value={certificate.summary}
                    handleChange={(e) => {
                      handleCertificateChange(e, i);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={handleAddCertificate}
            className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
          >
            <Plus className="mr-1 h-[12px] w-[12px]" />
            Add New Certificate
          </button>
        </>
      ) : (
        <button
          onClick={handleAddCertificate}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Certifications
        </button>
      )}
    </div>
  );
};

const WorkExperience = ({ data, setData, handleDateRangeChange }) => {
  const workExperiences = data.experiences || [];

  // Combine work.summary and work.details if work.summary exists
  useEffect(() => {
    if (!workExperiences.length) return;
    const updatedWorkExperiences = workExperiences.map((work) => {
      if (work.summary) {
        return {
          ...work,
          details: `${work.summary}\n\n${work.details}`,
        };
      }
      return work;
    });
    setData((prevData) => ({
      ...prevData,
      experiences: updatedWorkExperiences,
    }));
  }, []);

  const handleWorkExperienceChange = (index, field, value) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences[index] = {
      ...updatedExperiences[index],
      [field]: value,
    };
    setData((prevData) => ({
      ...prevData,
      experiences: updatedExperiences,
    }));
  };

  const handleEditorChange = (index, content) => {
    handleWorkExperienceChange(index, 'details', content);
  };

  const handleAddWorkExperience = (e) => {
    e.preventDefault();
    const newExperience = {
      company: '',
      details: '',
      'start-date': '',
      'end-date': '',
      keywords: [],
      summary: '',
      title: '',
    };
    setData((prevData) => ({
      ...prevData,
      experiences: [...workExperiences, newExperience],
    }));
  };

  return (
    <>
      {workExperiences.length > 0 ? (
        <div className="flex flex-col space-y-2">
          {workExperiences.map((work, i) => (
            <div className="" key={i}>
              <span className="text-[24px] md:text-[28px]">
                Work Experience {numToRomanNumeralsPair[i + 1]}
              </span>
              <div className="flex justify-start flex-col items-center space-x-2 space-y-5 w-full mb-5">
                <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                  <TextInput
                    label="Job Title"
                    type="text"
                    name="title"
                    value={work.title}
                    handleChange={(e) =>
                      handleWorkExperienceChange(
                        i,
                        'title',
                        e.target.value
                      )
                    }
                  />
                  <TextInput
                    label="Name of Company"
                    type="text"
                    name="company"
                    value={work.company}
                    handleChange={(e) =>
                      handleWorkExperienceChange(
                        i,
                        'company',
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="w-full flex space-x-2 justify-center items-center">
                  <div className="text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2 w-full">
                    <div className="">Professional Summary</div>
                    <ToastEditor
                      initialValue={work.details}
                      onChange={(content) =>
                        handleEditorChange(i, content)
                      }
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                  <DateInput
                    label="Start Year"
                    type="text"
                    name="start-date"
                    value={work['start-date']}
                    handleChange={(e) =>
                      handleDateRangeChange(e, i, 'experiences')
                    }
                  />
                  <DateInput
                    label="End Year"
                    type="text"
                    name="end-date"
                    value={work['end-date']}
                    handleChange={(e) =>
                      handleDateRangeChange(e, i, 'experiences')
                    }
                  />
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={handleAddWorkExperience}
            className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
          >
            <Plus className="mr-1 h-[12px] w-[12px]" />
            Add New Experience
          </button>
        </div>
      ) : (
        <button
          onClick={handleAddWorkExperience}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Work Experience
        </button>
      )}
    </>
  );
};

const Education = ({ setData, data, handleDateRangeChange }) => {
  const education = data.education;

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const education = [...data.education];
    education[i] = { ...education[i], [name]: value };
    setData((prevData) => ({ ...prevData, education: education }));
  };

  const handleAddEducation = (e) => {
    e.preventDefault();
    if (!data.education) {
      setData((prevData) => ({
        ...prevData,
        education: [
          {
            area: '',
            institution: '',
            startYear: '',
            endYear: '',
          },
        ],
      }));
      return;
    } else {
      setData((prevData) => ({
        ...prevData,
        education: [
          ...prevData.education,
          {
            area: '',
            institution: '',
            startYear: '',
            endYear: '',
          },
        ],
      }));
    }
  };

  return (
    <div className="flex flex-col ">
      {data?.education?.length > 0 ? (
        <>
          <div className="text-[24px] md:text-[28px]">
            Education/Academic Qualifications
          </div>
          <div className="mb-5">
            {education?.map((ed, i) => (
              <div
                key={i}
                className="flex justify-start flex-col items-center mb-5 space-y-4 w-full"
              >
                <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                  <TextInput
                    label="Degree Obtained"
                    type="text"
                    name="area"
                    value={ed.area}
                    handleChange={(e) => handleChange(e, i)}
                  />
                  <TextInput
                    label="Name of Institution"
                    type="text"
                    name="institution"
                    value={ed.institution}
                    handleChange={(e) => handleChange(e, i)}
                  />
                </div>
                <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
                  <DateInput
                    label="Start Year"
                    type="text"
                    name="startYear"
                    value={ed['start-year']}
                    handleChange={(e) =>
                      handleDateRangeChange(e, i, 'education')
                    }
                  />
                  <DateInput
                    label="End Year"
                    type="text"
                    name="endYear"
                    value={ed.endYear}
                    handleChange={(e) =>
                      handleDateRangeChange(e, i, 'education')
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleAddEducation}
            className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
          >
            <Plus className="mr-1 h-[12px] w-[12px]" />
            Add New Education
          </button>{' '}
        </>
      ) : (
        <button
          onClick={handleAddEducation}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Education
        </button>
      )}
    </div>
  );
};

const PersonalInformation = ({ handleChange, data, setData }) => {
  return (
    <div className="flex flex-col space-y-2 ">
      <div className="text-[24px] md:text-[28px] ">
        Personal Information
      </div>
      <div className="flex justify-start flex-col items-center space-x-2 space-y-5 w-full">
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
          <TextInput
            label="First Name"
            type="text"
            name="first-name"
            value={data['first-name']}
            handleChange={handleChange}
          />
          <TextInput
            label="Last Name"
            type="text"
            name="last-name"
            value={data['last-name']}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
          <TextInput
            label="Email"
            type="email"
            name="email"
            value={data.email}
            handleChange={handleChange}
          />
          <TextInput
            label="Phone Number"
            type="tel"
            name="phone"
            value={data.phone}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
          <Location data={data} setData={setData} />
        </div>
        <div className="w-full flex space-x-2 justify-center ">
          <TextInput
            label="Job Title"
            type="text"
            name="job-title"
            value={data['job-title']}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex space-x-2 justify-center ">
          <div
            className={`text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2  w-full }`}
          >
            <div className="">Professional Summary</div>
            <ToastEditor
              initialValue={data.summary}
              onChange={(content) =>
                setData({ ...data, summary: content })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Location = ({ data, setData }) => {
  const location = data.location;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      location: { ...prevData.location, [name]: value },
    }));
  };

  return (
    <>
      {location ? (
        <div className="w-full">
          <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
            <TextInput
              label="City"
              type="text"
              name="city"
              value={location.city}
              handleChange={handleChange}
            />
            <TextInput
              label="Region"
              type="text"
              name="region"
              value={location.region}
              handleChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
            <TextInput
              label="Address"
              type="text"
              name="address"
              value={location.address}
              handleChange={handleChange}
            />
            <TextInput
              label="Country"
              type="text"
              name="country"
              value={location.country}
              handleChange={handleChange}
            />
          </div>
          <TextInput
            label="Postal Code"
            type="text"
            name="postalCode"
            value={location.postalCode}
            handleChange={handleChange}
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

// @TODO Modify the way skills keywords are rendered and edited with
const Skills = ({ data, setData }) => {
  const skills = data.skills;

  const handleChange = (e, i) => {
    setData((prevData) => {
      const skills = [...prevData.skills];
      const updatedSkill = skills.map((skill, index) => {
        if (index === i) {
          return { ...skill, name: e.target.value };
        }
        return skill;
      });

      return { ...prevData, skills: updatedSkill };
    });
  };
  const handleKeywordChange = (e, i, j) => {
    setData((prevData) => {
      let currentSkill;
      const skills = [...prevData.skills];
      let updatedSkills = skills.map((skill, index) => {
        if (index === i) {
          currentSkill = { ...skill };
        }
        return skill;
      });

      const keywords = currentSkill.keywords;

      const updatedKeyword = keywords.map((keyword, index) => {
        if (index === j) {
          return e.target.value;
        }
        return keyword;
      });

      updatedSkills = skills.map((skill, index) => {
        if (index === i) {
          return { ...skill, keywords: updatedKeyword };
        }
        return skill;
      });

      return { ...prevData, skills: updatedSkills };
    });
  };

  const handleAddSkill = (e) => {
    e.preventDefault();
    if (!data.skills) {
      setData((prevData) => ({
        ...prevData,
        skills: [
          {
            id: uuidv4(),
            name: '',
            level: '',
            keywords: [],
          },
        ],
      }));
      return;
    } else {
      setData((prevData) => ({
        ...prevData,
        skills: [
          ...prevData.skills,
          {
            id: uuidv4(),
            name: '',
            level: '',
            keywords: [],
          },
        ],
      }));
    }
  };
  return (
    <div className="flex flex-col space-y-2">
      <div className="text-[24px] md:text-[28px]">Skills</div>
      <div className="flex justify-start flex-col items-center space-x-2 space-y-4 w-full">
        {skills?.map((skill, i) => (
          <div className="w-full" key={i}>
            <TextInput
              label=""
              type="text"
              name="name"
              value={skill.name}
              handleChange={(e) => handleChange(e, i)}
              className="w-full font-bold text-[18px]"
            />
            <div className="ml-4 md:ml-6">
              {skill.keywords?.map((keyword, j) => (
                <div
                  key={j}
                  className="flex w-full justify-start items-center space-x-2"
                >
                  <Circle className="bg-black fill-black w-2 h-2 rounded-full " />

                  <TextInput
                    label=""
                    type="text"
                    name="keyword"
                    value={keyword}
                    handleChange={(e) => handleKeywordChange(e, i, j)}
                    className="text-[14px] w-full"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}

        <button
          onClick={handleAddSkill}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Skill
        </button>
      </div>
    </div>
  );
};

const TextInput = ({
  type,
  label,
  name,
  value,
  handleChange,
  className,
}) => {
  return (
    <label
      htmlFor={name}
      className={`text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2  w-full ${
        label && 'mt-3'
      } ${type === 'textArea' && 'mt-4'}}`}
    >
      <span>{label}</span>
      {type === 'textArea' ? (
        <textarea
          value={value}
          id={name}
          name={name}
          type={type}
          className={`w-full rounded-[8px]  border-[1px] h-auto min-h-full border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1  focus:ring-brand-cyan-dark focus:border-transparent  ${className}`}
          onChange={handleChange}
          wrap="soft"
        ></textarea>
      ) : (
        <input
          value={value}
          id={name}
          name={name}
          type={type}
          className={` w-full rounded-[8px] border-[1px] border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1  focus:ring-brand-cyan-dark focus:border-transparent  ${className}`}
          onChange={handleChange}
        ></input>
      )}
    </label>
  );
};
const DateInput = ({ type, label, name, value, handleChange }) => {
  return (
    <label
      htmlFor={name}
      className={`text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2  w-full mt-3 ${
        type === 'textArea' && 'mt-4'
      }}`}
    >
      <span>{label}</span>

      <input
        value={value}
        id={name}
        name={name}
        type={type}
        className=" w-full rounded-[8px] border-[1px] border-[#D3D5DB] py-3 px-4 focus:outline-none focus:ring-1  focus:ring-brand-cyan-dark focus:border-transparent "
        onChange={handleChange}
      ></input>
    </label>
  );
};
