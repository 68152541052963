const reactiveResumeModel = {
  basics: {
    name: "",
    email: "",
    phone: "",
    photo: {
      url: "",
      filters: {
        size: 128,
        shape: "square",
        border: false,
        grayscale: false,
      },
      visible: true,
    },
    summary: "",
    website: "",
    headline: "",
    location: {
      city: "",
      region: "",
      address: "",
      country: "",
      postalCode: "",
    },
    profiles: [],
    birthdate: "",
  },
  sections: {
    work: {
      id: "work",
      name: "Work Experience",
      type: "work",
      items: [],
      columns: 1,
      visible: true,
    },
    awards: {
      id: "awards",
      name: "Awards",
      type: "basic",
      items: [],
      columns: 2,
      visible: true,
    },
    skills: {
      id: "skills",
      name: "Skills",
      type: "basic",
      items: [],
      columns: 2,
      visible: true,
    },
    projects: {
      id: "projects",
      name: "Projects",
      type: "basic",
      items: [],
      columns: 1,
      visible: true,
    },
    education: {
      id: "education",
      name: "Education",
      type: "basic",
      items: [],
      columns: 1,
      visible: true,
    },
    interests: {
      id: "interests",
      name: "Interests",
      type: "basic",
      items: [],
      columns: 2,
      visible: true,
    },
    languages: {
      id: "languages",
      name: "Languages",
      type: "basic",
      items: [],
      columns: 2,
      visible: true,
    },
    volunteer: {
      id: "volunteer",
      name: "Volunteer Experience",
      type: "basic",
      items: [],
      columns: 1,
      visible: true,
    },
    references: {
      id: "references",
      name: "References",
      type: "basic",
      items: [],
      columns: 1,
      visible: true,
    },
    publications: {
      id: "publications",
      name: "Publications",
      type: "basic",
      items: [],
      columns: 2,
      visible: true,
    },
    certifications: {
      id: "certifications",
      name: "Certifications",
      type: "basic",
      items: [],
      columns: 1,
      visible: true,
    },
  },
  metadata: {
    css: {
      value:
        "/* Enter custom CSS here */\n\n* {\n    outline: 1px solid #000;\n}",
      visible: false,
    },
    date: {
      format: "MM.DD.YYYY",
    },
    page: {
      format: "A4",
    },
    error: "",
    theme: {
      text: "#000000",
      primary: "#03a9f4",
      background: "#ffffff",
    },
    layout: [
      [
        ["work", "education", "projects", "volunteer", "references"],
        [
          "skills",
          "interests",
          "languages",
          "awards",
          "certifications",
          "publications",
        ],
      ],
    ],
    locale: "en",
    template: "kakuna",
    typography: {
      size: {
        body: 14,
        heading: 28,
      },
      family: {
        body: "Open Sans",
        heading: "Open Sans",
      },
    },
  },
  public: true,
};

export default reactiveResumeModel;
