import React from 'react';
import styled from 'styled-components';

const DashboardLayoutCols2 = ({ Main, Side }) => {
  return (
    <Container className=" md:grid-cols-9 w-full">
      <div className="left">{Main}</div>
      <div className="right">{Side}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    flex-direction: row;
    display: grid;
    grid-template-columns: 7fr 2fr;
    margin-right: 1rem;

    .left {
      scrollbar-color: #b4d6e4 rgba(217, 217, 217, 0.2);
      scrollbar-width: thin;
      border-right: 2px solid rgba(217, 217, 217, 0.2);
      padding-right: 2rem;

      /* Additional styles for cross-browser compatibility */
      /* For Webkit (Chrome, Safari, etc.) */
      &::-webkit-scrollbar {
        width: 8px; /* Matching the 'thin' scrollbar width */
        color: #b4d6e4;
        scrollbar-color: #b4d6e4;
      }
      &::-webkit-scrollbar-track {
        background: rgba(217, 217, 217, 0.2);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b4d6e4;
        border-radius: 4px; /* Rounded corners for the thumb */
        border: 1px solid rgba(217, 217, 217, 0.2); /* Same color border for seamless look */
        height: 50px; /* This is the height of the thumb */
      }

      /* For IE and Edge */
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .right {
      padding-left: 2rem;
    }
  }
`;

export default DashboardLayoutCols2;
