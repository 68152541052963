import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useResumesMutation,
  useSetDefaultResumeMutation,
  useGetUserDetailsMutation,
  useUploadPdfMutation,
} from '../store';
import { projectSlice, authSlice } from '../store';
import { Dashboard as UserDashboard } from '../components/Dashboard';
import Portal from '../components/common/Portal';
import { ModalWrapperStyle } from '../components/common/StyledComponents';
import { UploadResumeCard } from '../components/common/Cards';
import { useScreenWidth } from '../hooks';
import base64ToBlob from '../utils/base64ToBlob';
import { SpinnerLoader } from '../components/common/Loader';
import ContactUs from '../components/common/ContactUs';
import DashboardHeader from '../components/common/DashboardHeader';
import { fetchTask } from '../utils/fetchTask';

const REPRESENTS_UNSET_VALUES = -1;

const Dashboard = () => {
  const [continueLater, setContinueLater] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setuser] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const { getAccessTokenSilently } = useAuth0();
  const { activeResume, resume, resumes, defaultResume } =
    useSelector((state) => state.project);

  const [getUserDetails, { data, isLoading, isError }] =
    useGetUserDetailsMutation();

  const [
    uploadPdf,
    { data: uploadPdfData, isLoading: uploadPdfIsLoading },
  ] = useUploadPdfMutation();
  const [setDefaultResume, { isLoading: setDefaultResumeIsLoading }] =
    useSetDefaultResumeMutation();

  const getUser = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      dispatch(authSlice.actions.setAccessToken(token));
      await getUserDetails();
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }, [dispatch, getAccessTokenSilently, getUserDetails]);

  const checkAndNavigateToProjectView = useCallback(() => {
    const resume = localStorage.getItem('resume');
    const job = localStorage.getItem('job');
    const resumeTitle = localStorage.getItem('resumeTitle');

    if (!resume || !job || !resumeTitle) return;

    setLoading(true);
    dispatch(projectSlice.actions.setFromHomePage(true));
    const file = base64ToBlob(resume);

    const pdf2Object = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(authSlice.actions.setAccessToken(accessToken));

        let res = await uploadPdf(file);
        res = await fetchTask(res.data.task, accessToken);

        if (res.status === 'done') {
          dispatch(projectSlice.actions.setResumeObj(res.payload));
          dispatch(projectSlice.actions.setJob(job));
          dispatch(projectSlice.actions.setResumeTitle(resumeTitle));
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
        localStorage.removeItem('resume');
        localStorage.removeItem('job');
        localStorage.removeItem('resumeTitle');
        navigate('/project-view');
      }
    };

    pdf2Object();
  }, [dispatch, navigate, getAccessTokenSilently, uploadPdf]);

  const ensureDefaultResume = useCallback(async () => {
    if (!resumes || resumes.length === 0 || !data) return;

    const user = data.user;
    const defaultResumeID = user.default_resume;

    if (defaultResumeID !== REPRESENTS_UNSET_VALUES) {
      const newDefaultResume = resumes.find(
        (resume) => resume.id === defaultResumeID
      );

      if (!defaultResume && newDefaultResume) {
        dispatch(
          projectSlice.actions.setDefaultResume(newDefaultResume)
        );
      }
      if (!activeResume) {
        dispatch(
          projectSlice.actions.setActiveResume(newDefaultResume)
        );
      }
    } else {
      const firstResume = user.resumes[0];
      await setDefaultResume(firstResume.id);
      dispatch(projectSlice.actions.setDefaultResume(firstResume));
      dispatch(projectSlice.actions.setActiveResume(firstResume));
    }
  }, [data, dispatch, resumes, setDefaultResume]);

  useEffect(() => {
    checkAndNavigateToProjectView();
  }, [checkAndNavigateToProjectView]);

  useEffect(() => {
    getUser();
  }, [getUserDetails]);

  useEffect(() => {
    ensureDefaultResume();
  }, [resumes]);

  useEffect(() => {
    if (data) {
      let resumes = data.user.resumes;
      resumes = resumes.map((resume) => ({
        ...resume,
        content: JSON.parse(resume.content),
      }));
      dispatch(projectSlice.actions.setResumes(resumes));
    }
  }, [data, dispatch]);

  if (loading) {
    return (
      <SpinnerLoader text="Preparing your resume" withBox={true} />
    );
  }

  return (
    <>
      {(isLoading ||
        setDefaultResumeIsLoading ||
        uploadPdfIsLoading) && <SpinnerLoader />}
      {isError && (
        <div className="text-red-500 text-center">
          Oops... An Error Occurred
        </div>
      )}
      <div className="md:h-screen md:overflow-hidden bg-white dark:bg-black px-3 md:px-6 m-0">
        <DashboardHeader />
        <UserDashboard />
      </div>
      <div className="fixed right-0 bottom-8 mr-6">
        <ContactUs />
      </div>
    </>
  );
};

export default Dashboard;
