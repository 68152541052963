import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
const Register = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      to="/register"
      className="whitespace-nowrap w-full flex text-white h-14 md:h-12 px-6 rounded-lg items-center justify-center text-lg md:text-sm md:px-6 font-medium bg bg-gradient-to-r from-[#9494E5] to-[#00B3FF]"   
      style={{ width: '200%' }}
    >
      Get Started
    </button>
  );
};

export default Register;
