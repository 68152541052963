import React, { useState, useRef } from 'react';
import Portal from '../Portal';
import { useScreenWidth } from '../../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  projectSlice,
  authSlice,
  useUploadPdfMutation,
} from '../../../store';
import { SpinnerLoader } from '../Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchTask } from '../../../utils/fetchTask';

const UploadNewResumeBtn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);

  const fileInput = useRef();
  const fileInput2 = useRef();

  const { resume, resumeTitle, activeResume } = useSelector(
    (state) => state.project
  );
  const { user } = useSelector((state) => state.auth);

  const { getAccessTokenSilently } = useAuth0();
  const [uploadPdf, { data, isLoading, error }] =
    useUploadPdfMutation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loaderMsg = 'Preparing your resume...';

  const screenWidth = useScreenWidth();
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // handle submit for uploading file for the first time
  const handleUpload = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];
    dispatch(projectSlice.actions.setResume(file));
    handleSubmit();
    console.log('file', file);
  };

  const resetForm = () => {
    dispatch(projectSlice.actions.setResume(null));
  };

  const cleanUpMemory = () => {
    dispatch(projectSlice.actions.setResume(null));
    localStorage.removeItem('resume');
    localStorage.removeItem('job');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const file = fileInput.current.files[0];
    const pdf2Object = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        dispatch(authSlice.actions.setAccessToken(accessToken));

        let res = await uploadPdf(file);

        res = await fetchTask(res.data.task, accessToken);

        if (res.status === 'done') {
          dispatch(projectSlice.actions.setResumeObj(res.payload));
        } else {
          setTaskFailed(true);
        }
      } catch (error) {
        console.error(error.message);
        setTaskFailed(true);
      } finally {
        setLoading(false);
        cleanUpMemory();
        navigate('/project-view', {
          state: { from: location.pathname },
          replace: true,
        });
      }
    };

    pdf2Object();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    resetForm();
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    dispatch(projectSlice.actions.setResume(files[0]));
    setIsDragOver(false);
  };

  return (
    <>
      <label
        htmlFor="resume"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`md:flex min-w-[200px] w-full justify-center items-center border-dashed border rounded-3xl py-6 px-3 border-[#167aef52] bg-[#F6FCFF] ${
          !activeResume && 'md:py-0 md:px-0'
        }`}
      >
        {activeResume ? (
          <div className="flex justify-center items-center space-x-2 md:space-x-4">
            <div className="w-5 h-6 flex justify-center">
              <img
                src="/images/icons/doc.svg"
                alt="Upload Resume"
                className="w-full h-full"
              />
            </div>
            <div className="text-sm text-[#64A6EF] font-normal ">
              Upload New Resume
            </div>
          </div>
        ) : (
          <div className="flex md:flex-row justify-center items-center space-x-2 md:space-x-4 md:space-y-4 md:h-[180px]">
            <div className="w-5 h-6 flex justify-center items-center">
              <img
                src="/images/icons/doc.svg"
                alt="Upload Resume"
                className="w-full h-full"
              />
            </div>
            <div className=" text-[#64A6EF] font-normal ">
              Upload New Resume
            </div>
          </div>
        )}
        <input
          type="file"
          id="resume"
          name="resume"
          accept="docx,doc,application/pdf"
          className="hidden"
          ref={fileInput}
          onChange={handleSubmit}
        />
      </label>
      {loading && <SpinnerLoader text={loaderMsg} withBox={true} />}
    </>
  );
};

export default UploadNewResumeBtn;
