import { CompareResume } from '../../components/Dashboard';
import { useSelector } from 'react-redux';

const CompareResumePage = () => {
  const { activeResume } = useSelector((state) => state.project);

  return <CompareResume resume={activeResume} />;
};

export default CompareResumePage;
