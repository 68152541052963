import { styled } from 'styled-components';

const ModalWrapperStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 950;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(65, 66, 96, 0.62);
  backdrop-filter: blur(2px);

  .close {
    align-self: end;
  }
`;

export default ModalWrapperStyle;
