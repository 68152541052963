import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      to="/login"
      
      className="w-full text-white h-14 md:h-12 md:w-44 border-[1px] border-[#F2F2F2] rounded-lg flex items-center text-xl md:text-sm justify-center md:px-6 "
      style={{ width: '200%' }}
   >
      Login
    </button>
  );
};

export default Login;
