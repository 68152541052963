import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  user: null,
  createResumePermission: true,
  premiumUser: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.user = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setCreateResumePermission: (state, { payload }) => {
      state.createResumePermission = payload;
    },
    setPremiumUser: (state, { payload }) => {
      state.premiumUser = payload;
    },
  },
});

export default authSlice;
