import React from "react";
import { useSelector } from "react-redux";

const ResumeMatchingScoreCard = () => {
  const { score } = useSelector((state) => state.project);

  return (
    <div className="w-full flex items-center rounded-xl justify-between h-[96px] mt-6 mx-auto text-center" style={{backgroundColor:'#F5FCFF',  borderColor: '#a9e3ff', borderWidth: '1px', borderStyle: 'solid'}}>
      <div className="flex justify-start items-center  text-center  py-0 pl-10  space-x-1 md:space-x-5">
        <div className="h-[32px] w-[32px] md:h-[32px] md:w-[42px] ">
          <img
            src="/images/icons/magic.svg"
            alt="magic icon"
            className="w-full h-full"
          />
        </div>
        <div className=" flex flex-col text-left pt-1">
          <span className="text-[#105171] text-[14]">
            Your resume score is:
          </span>
          <span className="text-[#105171] text-[32px] font-bold">{score}<span className="text-[20px]">{' '}%</span></span>
        </div>
      </div>
      {/* <div className="h-[32px] w-[32px] md:h-[48px] md:w-[48px] mt-10">
        <img src="/images/icons/question.png" alt="question icon" />
      </div> */}
    </div>
  );
};

export default ResumeMatchingScoreCard;