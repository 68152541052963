import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

const TrailEndedCard = ({ setUpgradeModalIsOpen }) => {
  const navigate = useNavigate();
  return (
    <Container className="w-full h-[70%] lg:w-[660px] lg:h-[682px]  md:p-20">
      <div className="flex flex-col justify-center items-center w-full space-y-7  ">
        <div className="w-[176px] h-[154px]">
          <img src="/images/icons/delivery.png" alt="Delivery icon" />
        </div>

        <div className="flex justify-center flex-col items-center p-4">
          <span className="text-[#2D9CDB] text-[24px] md:text-[32px]  text-center mb-7">
            Oops! You used your free trials
          </span>
          <div className="flex flex-col justify-center items-center">
            <div className="text-[14px] text-[#312E2E] mb-2 flex flex-col text-center">
              <span>
                Upgrade to continue comparing your resume with a job
                description for just{' '}
                <span className="font-bold">
                  monthly subscription fee of{' '}
                  <span className="line-through">$29.99 </span> $9.99
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center w-full space-y-7 md:space-y-0 space-x-0 md:space-x-7">
          <Link
            to="/subscription"
            className="bg-[#2D9CDB] rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-white flex justify-center items-center"
          >
            <div className="w-[24px] h-[24px]">
              <img
                src="/images/icons/crown-white.svg"
                alt="alt"
                className="w-full h-full"
              />
            </div>
            <span>
              Upgrade for <span className="line-through">$29.99</span>{' '}
              $9.99
            </span>
          </Link>
          <button
            onClick={() => {
              navigate('/user-profile');
              setUpgradeModalIsOpen(false);
            }}
            className="border-[#828282] border rounded text-[14px] px-4 py-3 w-full lg-w-[224px] text-[#828282]"
          >
            Back to Dashboard
          </button>
        </div>
      </div>
      <button
        onClick={() => setUpgradeModalIsOpen(false)}
        className="hidden lg:flex absolute top-0 right-0 text-black opacity-40 text-[32px] mt-6 mr-6 "
      >
        X
      </button>
    </Container>
  );
};

export default TrailEndedCard;

const Container = styled.div`
  position: relative;
  background: #fff;

  @media (min-width: 1024px) {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 13px 0px rgba(88, 113, 132, 0.1);
  }
`;
