import React from "react";
import { ResumeTemplates } from "../components/common/Cards";
import Footer from "../components/common/Footer";
import DashboardHeader from "../components/common/DashboardHeader";
import ContactUs from "../components/common/ContactUs";

const ExportPage = () => {
  return (
    <div className="flex flex-col justify-between xl:block items-center max-w-full w-full min-h-screen bg-white dark:bg-black">
      <DashboardHeader />

      <div className="py-6 w-full flex justify-center items-center">
        <ResumeTemplates />
      </div>
      <div className="xl:hidden w-full">
        <Footer />
      </div>
      <div className="fixed right-0 bottom-0 m-8 ">
        <ContactUs />
      </div>
    </div>
  );
};

export default ExportPage;
