function splitDateRange(date) {
  if (!date) return {};

  if (typeof date !== "string") return date;

  if (date.includes("_")) {
    const dates = date.split(" - ");
    return {
      start: dates[0].trim() ? dates[0].trim() : "null",
      end: dates[1].trim() ? dates[1].trim() : "null",
    };
  }

  return null;
}

export default splitDateRange;
