import { v4 as uuidv4 } from "uuid";

function formatSkills(skills) {
  if (!skills) return [];
  return skills.map((skill) => ({
    ...skill,
    id: skill.id || uuidv4,
  }));
}

export default formatSkills;
