import React, { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { ChevronDown, ChevronUp, User } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Avatar = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const [location, setLocation] = React.useState(null);
  const { logout } = useAuth0();
  const { user } = useSelector((state) => state.auth);
  const menuRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setLocation(pathname);
  }, [pathname]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);

  const handleDropDown = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="relative">
      <button
        onClick={handleDropDown}
        className="flex justify-center items-center space-x-2 text-[14px] font-medium text-black "
      >
        <div
          className={`${
            location !== '/' ? 'text-black' : 'text-white'
          } hidden md:block`}
        >
          {user?.given_name}
        </div>
        <div className="rounded-full z-10 w-9 h-9">
          <img
            src={user?.picture || '/images/icons/avatar.svg'}
            alt="avatar"
            className="rounded-full w-full h-full object-cover "
          />
        </div>
        <>
          {showMenu ? (
            <ChevronUp
              className={`${
                location !== '/' ? 'text-black' : 'text-white'
              } h-4 w-4 `}
            />
          ) : (
            <ChevronDown
              className={`${
                location !== '/' ? 'text-black' : 'text-white'
              } h-4 w-4 `}
            />
          )}
        </>
      </button>
      {showMenu && (
        <div ref={menuRef}>
          <Menu logout={logout} location={location} />
        </div>
      )}
    </div>
  );
};

const Menu = ({ logout, location }) => {
  return (
    <div className="flex flex-col items-start space-y-3 absolute bg-white py-[20px]  right-[-30%] xl:right-[-50px]  z-40 mt-3 box-shadow w-[211px] rounded text-[14px] font-medium">
      {location === '/' && (
        <Link
          to="/user-profile"
          className=" text-black hover:bg-gray-300 w-full py-2 px-4 rounded flex justify-start items-center "
        >
          <div className="w-4 h-4 mr-3">
            <User className="w-full h-full" />
          </div>
          Dashboard
        </Link>
      )}
      <Link
        to="/account"
        className=" text-black hover:bg-gray-300 w-full py-2 px-4 rounded flex justify-start items-center "
      >
        <div className="w-4 h-4 mr-3">
          <User className="w-full h-full" />
        </div>
        My Account
      </Link>
      <button
        onClick={() =>
          logout({
            logoutParams: { returnTo: window.location.origin },
          })
        }
        className=" text-black  hover:bg-gray-300 w-full py-2 px-4 rounded flex justify-start items-center"
      >
        <div className="w-4 h-4 mr-3">
          <img
            src="/images/icons/power.png"
            alt="logout"
            className="w-full h-full"
          />
        </div>
        Logout
      </button>
    </div>
  );
};

export default Avatar;
