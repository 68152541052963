import { v4 as uuidv4 } from "uuid";

function formatWorkExperience(experiences) {
  return experiences?.map((experience) => ({
    id: experience.id || uuidv4(),
    name: experience.company,
    position: experience.title,
    summary: experience.details,
    date: {
      start: experience["start-date"],
      end: experience["end-date"],
    },
  }));
}

export default formatWorkExperience;
