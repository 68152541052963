import React from 'react';
import styled from 'styled-components';
import ProjectTitle from '../Dashboard/ProjectTitle';
import { useSelector } from 'react-redux';

const ResumeLayout = ({
  VerticalResumeTab,
  HorizontalResumeTab,
  ResumeForm,
  NextStep,
}) => {
  const { page } = useSelector((state) => state.project);
  return (
    <Container className="w-full md:mt-0 hide-scrollbar">
      <div className="hide-scrollbar lg:min-h-full grid grid-cols-9 h-full">
        <div>
          <div className="lg:hidden">{HorizontalResumeTab}</div>
          <div className="h-full flex items-center mt-[-102px]">
            {VerticalResumeTab}
          </div>
        </div>
        <div className="brand-scrollbar lg:min-h-full lg:overflow-y-scroll flex flex-col items-center justify-start">
          <ProjectTitle />
          {ResumeForm}
        </div>
        <div
          className={`next brand-scrollbar overflow-scroll flex justify-center items-center pb-6`}
        >
          {NextStep}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .grid {
    display: flex;
    flex-direction: column;
  }

  .next {
    max-height: 90vh;
    overflow-y: auto;
  }

  @media (min-width: 1024px) {
    max-height: 100vh;
    overflow: hidden;
    .grid {
      display: grid;
      grid-template-columns: 2fr 6fr 3fr;
      gap: 2rem;
    }

    .title {
      display: grid;
      grid-template-columns: 8fr 3fr;
    }
  }
`;

export default ResumeLayout;
