import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectSlice } from '../../store';
import { useScreenWidth } from '../../hooks';
import { FixResumeBtn, ExportCvBtn, UndoChangesBtn } from './Buttons';
import { StepTracker2 } from './StepTracker';
import { ChevronDown, ChevronUp } from 'react-feather';

const Insights = ({ data }) => {
  const dispatch = useDispatch();
  const insights = useSelector((state) => state.project.insights);
  const page = useSelector((state) => state.project.page);
  const continueWithoutFixing = useSelector(
    (state) => state.project.continueWithoutFixing
  );

  const [fixResume, setFixResume] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showAllInsights, setShowAllInsights] = useState(false);

  useEffect(() => {
    if (fixResume || continueWithoutFixing) {
      dispatch(projectSlice.actions.setPage(3));
    }
  }, [fixResume, continueWithoutFixing, dispatch]);

  useEffect(() => {
    setAllSelected(insights.every((insight) => insight.selected));
  }, [insights]);

  const handleContinueWithoutFixing = () => {
    dispatch(projectSlice.actions.setEnhancedResume(data));
    dispatch(projectSlice.actions.setContinueWithoutFixing(true));
    setFixResume(false);
    setShowInsights(false);
  };

  const handleCheckboxToggle = (index) => {
    const updatedInsights = insights.map((insight, idx) =>
      idx !== index
        ? insight
        : { ...insight, selected: !insight.selected }
    );
    setAllSelected(
      updatedInsights.every((insight) => insight.selected)
    );
    dispatch(projectSlice.actions.setInsights(updatedInsights));
  };

  const handleSelectAll = () => {
    const updatedInsights = insights.map((insight) => ({
      ...insight,
      selected: !allSelected,
    }));
    setAllSelected(!allSelected);
    dispatch(projectSlice.actions.setInsights(updatedInsights));
  };

  const renderButtons = () => {
    if (page === 2) {
      return (
        <div>
          <FixResumeBtn data={data} setFixResume={setFixResume} />
          <button
            onClick={handleContinueWithoutFixing}
            className="text-[14px] flex justify-center items-center py-3 px-5 w-full space-x-3"
            
          >
            <div>
              <img
                src="/images/icons/export.png"
                alt="download icon"
              />
            </div>
            <span className="underline text-[#333333] font-medium">
              Export Without Fixes
            </span>
          </button>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center space-y-3 mb-10 lg:mb-0 h-full justify-center">
        <span className="text-[16px] text-[#727272] mb-6 text-center">
         Fantastic work! <br></br>Would you like to export the final results?
        </span>
        <ExportCvBtn data={data}/>
      
      </div>
    );
  };

  const renderInsightList = () => {
    if (continueWithoutFixing) return null;

    return (
      <div
        className={`${
          !showInsights && 'hidden'
        } lg:flex flex-col justify-center items-center space-y-2 w-full`}
      >
        <SelectAll
          handleSelectAll={handleSelectAll}
          allSelected={allSelected}
          insightsCount={insights.length}
        />
        {showAllInsights
          ? insights.map((insight, i) => (
              <Insight
                key={i}
                insight={insight}
                handleCheckboxToggle={() => handleCheckboxToggle(i)}
              />
            ))
          : insights
              .slice(0, 4)
              .map((insight, i) => (
                <Insight
                  key={i}
                  insight={insight}
                  handleCheckboxToggle={() => handleCheckboxToggle(i)}
                />
              ))}
        <button
          onClick={() => setShowAllInsights(!showAllInsights)}
          className="flex w-full text-brand-cyan-dark underline"
        >
          {!showAllInsights ? (
            <div className="flex items-center space-x-3 text-[14px]">
              <div>See {insights.length - 4} more suggestions</div>
              <ChevronDown className="w-5 h-5" />
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              <div>Show less</div>
              <ChevronUp className="w-5 h-5" />
            </div>
          )}
        </button>
      </div>
    );
  };

  return (
    <div>
      {page === 3 ? (
        <div className="flex flex-col lg:h-full items-center w-full justify-center space-y-10">
          <StepTracker2 currentStep={page} />
          <div className="w-full flex-col justify-center items-center space-y-3">
            {renderButtons()}
          </div>
        </div>
      ) : (
        <div className="flex flex-col lg:h-full items-center w-full justify-start space-y-10">
          <StepTracker2 currentStep={page} />
          {page !== 3 && (
            <div className="flex flex-col justify-start items-start space-y-8 w-full">
              <div className="w-full">
                {!fixResume ? (
                  <InsightsTitle
                    showInsights={showInsights}
                    setShowInsights={setShowInsights}
                  />
                ) : (
                  <div className="hidden lg:block">
                    <UndoChangesBtn setFixResume={setFixResume} />
                  </div>
                )}
              </div>
              {renderInsightList()}
            </div>
          )}
          <div className="w-full flex-col justify-center items-center space-y-3">
            {renderButtons()}
          </div>
        </div>
      )}
    </div>
  );
};

const Insight = ({ insight, handleCheckboxToggle }) => (
  <button
    onClick={handleCheckboxToggle}
    className="flex justify-start items-start space-x-2 w-full text-left"
  >
    <div className="min-w-[20px] min-h-[20px] w-[20px] h-[20px]">
      <img
        src={`/images/icons/checkbox${
          insight.selected ? '-checked' : ''
        }.svg`}
        alt={insight.selected ? 'checked icon' : 'unchecked icon'}
        className="w-full h-full"
      />
    </div>
    <div className="flex flex-col w-full justify-start items-start">
      <span
        className={`italic text-[12px] mb-1 opacity-60 text-[#312E2E] ${
          insight.selected && 'line-through'
        }`}
      >
        {insight.insight}
      </span>
      <hr className="w-[80%] fill-red-600 my-4" />
    </div>
  </button>
);

const SelectAll = ({
  handleSelectAll,
  allSelected,
  insightsCount,
}) => (
  <button
    onClick={handleSelectAll}
    className="flex justify-start items-start space-x-2 w-full text-left font-semibold"
  >
    <div className="min-w-[20px] min-h-[20px] w-[20px] h-[20px]">
      <img
        src={`/images/icons/checkbox${
          allSelected ? '-checked' : ''
        }.svg`}
        alt={allSelected ? 'checked icon' : 'unchecked icon'}
        className="w-full h-full"
      />
    </div>
    <div className="flex flex-col w-full justify-start items-start">
      <span
        className={`italic text-[14px] mb-1 opacity-80 text-[#312E2E] ${
          allSelected && 'line-through'
        }`}
      >
        Select all {insightsCount} suggestions
      </span>
    </div>
  </button>
);

const InsightsTitle = ({ setShowInsights, showInsights }) => {
  const width = useScreenWidth();

  useEffect(() => {
    if (width > 1023) setShowInsights(true);
  }, [width, setShowInsights]);

  return (
    <button
      disabled={width > 1023}
      onClick={() => setShowInsights(!showInsights)}
      className="bg-[#E0FFE3] flex justify-start items-center space-x-3 py-2 lg:py-2 px-4 rounded border-[#28D32F] border-[0.5px]"
    >
      <div>
        <img
          className="w-[24px] h-[24px]"
          src="/images/icons/bulb-green.svg"
          alt="bulb icon"
        />
      </div>
      <div className="flex items-center justify-between text-left w-full text-[14px] text-brand-green">
        <span className="text-brand-green">
          Select the fixes you would like to apply <br />
          <span className="lg:hidden">
            {showInsights ? 'Click to Hide' : 'Click to Show'}{' '}
            insights
          </span>
        </span>
      </div>
    </button>
  );
};

export default Insights;
