import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useEnhanceResumeMutation,
  useUpdateResumeMutation,
} from '../../../store';
import { CookingLoader } from '../../common/Loader';
import { authSlice, projectSlice } from '../../../store';
import { fetchTask } from '../../../utils/fetchTask';
import { TrailEndedCard } from '../Cards';
import Portal from '../Portal';
import { ModalWrapperStyle } from '../StyledComponents';

const FixResumeButton = ({ setFixResume, data: resume }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);
  const { resumeId, selectedInsights, resumeTitle } = useSelector(
    (state) => state.project
  );

  const {
    isLoading: authIsLoading,
    getAccessTokenSilently,
    error: authError,
  } = useAuth0();
  const dispatch = useDispatch();
  const [enhanceResume] = useEnhanceResumeMutation();
  const [
    updateResume,
    { isLoading: resumeIsUpdating, error: errorUpdatingResume },
  ] = useUpdateResumeMutation();

  const handleFixResume = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });
      dispatch(authSlice.actions.setAccessToken(accessToken));

      const insights = JSON.stringify(selectedInsights);
      await updateResumeOnServer();
      let res = await enhanceResume({
        resume_id: resumeId,
        insights,
      });

      if (res.error) {
        if (res.error.status === 400) {
          dispatch(
            authSlice.actions.setCreateResumePermission(false)
          );
          setModalIsOpen(true);
          return;
        } else {
          console.error(res.error);
          return;
        }
      }

      const taskResult = await fetchTask(res.data.task, accessToken);
      handleTaskResult(taskResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateResumeOnServer = async () => {
    dispatch(projectSlice.actions.setResumeObj(resume));
    const jsonData = JSON.stringify(resume);
    await updateResume({
      id: resume.id,
      resume: {
        content: jsonData,
        title: resumeTitle || resume?.title,
      },
    });
  };

  const handleTaskResult = (result) => {
    if (result.status === 'done') {
      dispatch(
        projectSlice.actions.setEnhancedResume(result.payload)
      );
      setFixResume(true);
    } else if (result.status === 'failed') {
      setTaskFailed(true);
    }
  };

  if (authIsLoading || loading || resumeIsUpdating) {
    return (
      <CookingLoader text="We are doing some magic 🪄✨  It won't take a minute 👍 " />
    );
  }

  if (authError) {
    return (
      <div className="text-red-500">Oops... authentication error</div>
    );
  }

  return (
    <>
      <button
        onClick={handleFixResume}
        disabled={selectedInsights.length === 0}
        className={`bg-[#2D9CDB] rounded flex justify-center items-center space-x-6 py-3 text-white w-full ${
          selectedInsights.length === 0
            ? 'opacity-50 cursor-not-allowed'
            : ''
        }`}
      >
        <img
          src="/images/icons/wand-white.svg"
          alt="wand"
          className="w-[24px] h-[24px]"
        />
        <span>Apply Fixes</span>
      </button>
      {modalIsOpen && (
        <Portal>
          <ModalWrapperStyle>
            <TrailEndedCard setUpgradeModalIsOpen={setModalIsOpen} />
          </ModalWrapperStyle>
        </Portal>
      )}
    </>
  );
};

export default FixResumeButton;
